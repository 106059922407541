import React from "react";
import { checkBankList } from "../../../utils/validations";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import Autocomplete from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";

function BankList({ form, setForm, setFocusOnInput = () => {}, ...props }) {
  const { t } = useTranslation();

  return (
    <>
      <Autocomplete
        className="homeMobile__filling__content__input"
        value={form.bank_list.value}
        onChange={(event, newValue) => {
          setForm((prev) => {
            return {
              ...prev,
              bank_list: { ...prev.bank_list, value: newValue || "" },
            };
          });
        }}
        options={props.channelList?.[props.method]?.map?.((item) => item?.data?.[0])}
        getOptionLabel={(option) => option?.bank_name}
        renderInput={(params) => (
          <TextField
            {...params}
            label={t(props.method === "Cash" ? "agent" : "bank_select")}
            variant="outlined"
            error={!form.bank_list.valid}
            helperText={!form.bank_list.valid && t(props.method === "Cash" ? "agent_select" : "bank_select")}
            size="small"
            inputProps={{ ...params.inputProps, autocomplete: "new-password" }}
          />
        )}
        onFocus={() => {
          setFocusOnInput(true);
        }}
        onClick={(event) => {
          event.stopPropagation();
        }}
        onBlur={() => {
          checkBankList({ form: form, setForm: setForm });
        }}
      />
    </>
  );
}

const mapStateToProps = (state, ownprops) => {
  return {
    channelList: state.channelList,
    method: state.method,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(BankList);
